import './App.css';
import io from 'socket.io-client';
import { useEffect, useState } from 'react'
import knightBlack from "./knightBlack.png";
import knightWhite from "./knightWhite.png";
import dolphinBlack from "./dolphinBlack.png";
import dolphinWhite from "./dolphinWhite.png";
import princessBlack from "./princessBlack.png";
import princessWhite from "./princessWhite.png";

const socket = io.connect("https://jinx-game-server-949d85f16d7a.herokuapp.com/")

//THIS IS THE FRONTEND

let screenVar = window.matchMedia("(max-width: 800px)")


let messages = [];
let messagesReceived = [];
let permUserIdentification = "";
let hasInitialized = false;

let fightersWhite = {
        0: null,
        1: knightWhite,
        2: princessWhite,
        3: dolphinWhite,

        };

let fightersBlack = {
        0: null,
        1: knightBlack,
        2: princessBlack,
        3: dolphinBlack,
        };

let isInGame = false;
let waveMessage = "";
let opponentWaveMessage = "";
let waveRoom = "";
let code = "";
let round = 1;
let opponentName = "";
let hasWon = false;


function App() {
  const [room, setRoom] = useState("");
  const [roomReceived, setRoomReceived] = useState("");
  const [helpPage, setHelpPage] = useState(1);
  const[readyReceived, setReadyReceieved] = useState("");

  const [yourName, setYourName] = useState("");
  const [yourNameReceived, setNameReceived] = useState("");

  const [roomPlayersReceived, setRoomPlayersReceived] = useState("");

  const [message, setMessage] = useState("");
  const [messageReceived, setMessageReceived] = useState("");
  //const [UserId, setUserId] = useState("");

  const [timer, setTimer] = useState(5);
  const [inGame, setInGame] = useState(false);

  const [sendStartGame, setSendStartGame] = useState("");

  const [gameTime, setGameTime] = useState("0");

  const [playerSelection, setPlayerSelection] = useState(1);
  const [opponentSelection, setOponnentSelection] = useState(0);

  function restartGameRequest(){
    socket.emit("send_restart_request", permUserIdentification);
  }

  function imgRight(){
    if(playerSelection >= 3){
      setPlayerSelection(1);
    }else{
     setPlayerSelection(playerSelection+1); 
    }
      console.log(playerSelection);
  }

  function displayHelp(){
    document.getElementById("helpBox").style.transform="scale(1)";
  }

  function closeHelp(){
    document.getElementById("helpBox").style.transform="scale(0.001%)";
    setHelpPage(1);
  }

  function checkSubmitButton(){
    if(messages.length === 0){
      let tempString = document.getElementById("guess").value
      if((hasWhiteSpace(tempString) !== true) && (tempString !== "")){
        document.getElementById("lockInButton").style.opacity = "100%";
      }else{
        document.getElementById("lockInButton").style.opacity = "50%";
      }
    
    }
    else{
    //do something to the m's
    let tempM = "m" + (messages.length).toString();
    let tempString = document.getElementById(tempM).value;
    if((hasWhiteSpace(tempString) !== true) && (tempString !== "")){
      document.getElementById("lockInButton").style.opacity = "100%";
    }else{
      document.getElementById("lockInButton").style.opacity = "50%";
    }
  }
}

  function imgLeft(){
    if(playerSelection <= 1){
      setPlayerSelection(3);
    }else{
     setPlayerSelection(playerSelection-1); 
    }
    console.log(playerSelection);
  }


  function rotateTitle2(){
    //if the screen is under 800 pixels
    if(screenVar.matches){
      //document.getElementById("titleDiv").style.transform+="translateX(-3px)";
    }else{
      document.getElementById("titleDiv").style.transform+="translateX(-6px)"; 
      document.getElementById("titleDiv").style.transform+="rotate(-15deg)"; 
    }

    document.getElementById("playerContainer").style.transform="scale(0)";
    document.getElementById("opponentContainer").style.transform="scale(0)";
    
  }

  function exitMenu(){
    document.getElementById("titleDiv").style.transitionDuration = "400ms";
    document.getElementById("playButton").style.transitionDuration = "400ms";
    document.getElementById("playButton").style.transform+="scale(0)";
    restartGame();
    isInGame = false;
    document.getElementById("submitRoomButton").style.display = "block";
  }

  function displayStartButton(){
    document.getElementById("playButton").style.transform="scale(1)";
  }

  function mainMenuFunc(){
    if(!hasInitialized){
      
      document.getElementById("titleDiv").style.transitionDuration = "900ms";
      document.getElementById("playButton").style.transitionDuration = "900ms";
      //if the screen is under 800 pixels
      if(screenVar.matches){ 
        document.getElementById("titleDiv").style.transform="translateX(-6px)"; 
        document.getElementById("titleDiv").style.top="38%"; 
        document.getElementById("titleDiv").style.transform+="scale(250%)";
        setTimeout(rotateTitle2, 1250)
        setTimeout(displayStartButton, 1250);    
      }
      else{
      document.getElementById("titleDiv").style.top="35%"; 
      document.getElementById("titleDiv").style.transform+="scale(200%)";
      setTimeout(rotateTitle2, 1250)
      setTimeout(displayStartButton, 2500); 
      }
      
      
      hasInitialized = true; 
    }
    
  }

  function sendLockIn(){
      
      console.log("Round: " + round)
      if(messages.length === 0){
        let tempString = document.getElementById("guess").value
        if((hasWhiteSpace(tempString) !== true) && (tempString !== "")){
          socket.emit("send_lock_in", permUserIdentification);
          document.getElementById("lockInButton").style.visibility="hidden";
          document.getElementById("guess").readOnly=true;
          document.getElementById("guess").style.backgroundColor="#DFDFDF";
          document.getElementById("enterNotification").style.visibility = "hidden";
        }
      
      }
      else{
      //do something to the m's
      let tempM = "m" + (messages.length).toString();
      let tempString = document.getElementById(tempM).value;
      if((hasWhiteSpace(tempString) !== true) && (tempString !== "")){
        document.getElementById(tempM).readOnly=true;
        socket.emit("send_lock_in", permUserIdentification);
        document.getElementById("lockInButton").style.visibility="hidden";
        document.getElementById(tempM).style.backgroundColor="#DFDFDF";
      }
      
      } 
    

  }


  function generateCodeButtonFunction(){
    opponentName = null;
    setRoomPlayersReceived(opponentName);
    document.getElementById("generateCodeButton").style.display = "block";
    document.getElementById("generateCodeButton").innerText = "Generate Friend Code";
    document.getElementById("generateCodeButton").style.backgroundColor = "black";
    document.getElementById("generateCodeButton").style.color = "white";
    document.getElementById("clipBoardNotif").style.display = "block"; 
  }

  function restartGame(){
    
    document.getElementById("guess1").style.visibility="hidden";
    document.getElementById("guess").style.visibility="hidden";
    document.getElementById("lockInButton").style.visibility="hidden";
    document.getElementById("submitRoomButton").style.display = "none";
    

    document.getElementById("titleDiv").style.transform="rotate(0deg)";
    document.getElementById("titleDiv").style.transform+="translateX(0px)";
    document.getElementById("titleDiv").style.transform+="scale(1)";
    document.getElementById("titleDiv").style.top="10px";
    document.getElementById("playAgainButton").style.transform+="scale(0)";
    document.getElementById("winDiv").style.transform+="scale(0)";
    if(screenVar.matches){
      document.getElementById("playerContainer").style.transform="scale(0.9)";
      document.getElementById("opponentContainer").style.transform="scale(0.9)";  
    }else{
      document.getElementById("playerContainer").style.transform="scale(1)";
      document.getElementById("opponentContainer").style.transform="scale(1)";
    }
    
    messages.length = 0;
    messagesReceived.length = 0;
    round = 1;
    isInGame = false;
    setInGame(false);
    document.getElementById("playerCircle").style.transform="translateY(0px)";
    document.getElementById("opponentCircle").style.transform="translateY(0px)";
    
    document.getElementById("nameDiv").style.transform="translateY(0px)";
    document.getElementById("yourFriendsName").style.transform="translateY(0px)";
    document.getElementById("guess").style.transform="translateY(-235px)";
    document.getElementById("guess").value="";
    
    document.getElementById("guess").style.backgroundColor="white";
    document.getElementById("guess1").style.transform="translateY(-158px)";
    document.getElementById("guess1").value="";
    
    document.getElementById("guess1").style.backgroundColor="#DFDFDF";
    document.getElementById("guess").readOnly=false;
    
    setTimer("5");

    let tempM = "m";
    let tempO = "o";
    for(let i = 1; i<11; i++){
      tempM = "m" + i.toString();
      tempO = "o" + i.toString();
      document.getElementById(tempM).value="";
      document.getElementById(tempO).value="";
      document.getElementById(tempO).style.opacity="100%";
      document.getElementById(tempM).style.opacity="100%";
      document.getElementById(tempO).style.backgroundColor="#DFDFDF";
      document.getElementById(tempM).style.backgroundColor="white";
      document.getElementById(tempM).style.visibility="hidden";
      document.getElementById(tempO).style.visibility="hidden";
    }

    document.getElementById("o1").style.transform="translateY(-323px)";
    document.getElementById("o2").style.transform="translateY(-488px)";
    document.getElementById("o3").style.transform="translateY(-653px)";
    document.getElementById("o4").style.transform="translateY(-818px)";
    document.getElementById("o5").style.transform="translateY(-983px)";
    document.getElementById("o6").style.transform="translateY(-1148px)";
    document.getElementById("o7").style.transform="translateY(-1313px)";
    document.getElementById("o8").style.transform="translateY(-1478px)";
    document.getElementById("o9").style.transform="translateY(-1643px)";
    document.getElementById("o10").style.transform="translateY(-1808px)";

    document.getElementById("m1").style.transform="translateY(-400px)";
    document.getElementById("m2").style.transform="translateY(-565px)";
    document.getElementById("m3").style.transform="translateY(-730px)";
    document.getElementById("m4").style.transform="translateY(-895px)";
    document.getElementById("m5").style.transform="translateY(-1060px)";
    document.getElementById("m6").style.transform="translateY(-1225px)";
    document.getElementById("m7").style.transform="translateY(-1390px)";
    document.getElementById("m8").style.transform="translateY(-1555px)";
    document.getElementById("m9").style.transform="translateY(-1720px)";
    document.getElementById("m10").style.transform="translateY(-1885px)";


    socket.emit("send_restart", permUserIdentification);


  }

  function moveTitle(){
    if(screenVar.matches){
    document.getElementById("titleDiv").style.top="38%";  
    }else{
    document.getElementById("titleDiv").style.top="25%";   
    }
  
  }

  function rotateTitle(){
    if(!screenVar.matches){
    document.getElementById("titleDiv").style.transform+="rotate(-10deg)";
    document.getElementById("titleDiv").style.transform+="translateX(-12px)";  
    }
    document.getElementById("playerContainer").style.transform="scale(0)";
    document.getElementById("opponentContainer").style.transform="scale(0)";
    
  }

  function enlargeTitle(){
    if(screenVar.matches){
      document.getElementById("titleDiv").style.transform = "scale(1)";  
    }
    document.getElementById("titleDiv").style.transform+="scale(2)";
    document.getElementById("winDiv").style.transform="scale(1)";
    document.getElementById("playAgainButton").style.transform="scale(1)";
  }



  function winFunc(winningWord){
    document.getElementById("winDiv").value=winningWord;
    document.getElementById("winDiv").style.backgroundColor= "#90ee90";
    document.getElementById("waitingDiv").style.visibility = "hidden";
    if(round === 1){
      document.getElementById("guess").style.backgroundColor="#90ee90";
      document.getElementById("guess1").style.backgroundColor="#90ee90";
    }else{
      let tempM = "m" + (round-1).toString();
      let tempO = "o" + (round-1).toString();
      document.getElementById(tempM).style.backgroundColor="#90ee90";
      document.getElementById(tempO).style.backgroundColor="#90ee90";
      
    }
    document.getElementById("sayTheSameThing").style.transform+="scale(0)";
    setTimeout(moveTitle, 1500);
    setTimeout(rotateTitle, 2000);
    setTimeout(enlargeTitle, 3000);
    //setTimeout(removeTitle, 3500);
  }

  function loseFunc(){
    document.getElementById("winDiv").value="Out of time...";
    document.getElementById("winDiv").style.backgroundColor="#FAA0A0";
    document.getElementById("waitingDiv").style.visibility = "hidden";
    document.getElementById("sayTheSameThing").style.transform+="scale(0)";
    setTimeout(moveTitle, 1500);
    setTimeout(rotateTitle, 2000);
    setTimeout(enlargeTitle, 3000);
    //setTimeout(removeTitle, 3500);
  }
 
  function randomFromArray(array){
    return array[Math.floor(Math.random() * array.length)];
  }
  
  
  function createRandomWord (){
  const prefix = randomFromArray([
    "Banana",
    "Dog",
    "Excavation",
    "Torch",
    "Light",
    "Love",
    "Transportation",
    "Refrigerator",
    "Lucky",
    "Computer",
    "Surgery",
    "Device",
    "Chest",
    "Marriage",
    "Airplane",
    "Car",
    "Boat",
    "Tension",
    "Passenger",
    

  ]);
  
  return `${prefix}`;
  }

  function moveUpSaySameThing(){
    if(isInGame){
      if(screenVar.matches){
        //if screen is phone size make say same thing dissappear
        document.getElementById("sayTheSameThing").style.visibility="hidden"; 
      }else{
        document.getElementById("sayTheSameThing").style.transform="translateY(-290px)";
        document.getElementById("sayTheSameThing").style.transform+="scale(0.5)";  
      }
     
    }
    
  }

  // function enlargeSaySameThing(){
  //   document.getElementById("sayTheSameThing").style.scale="200%";
  // }

  function makeFirstGuessVisible(){
    if(isInGame){
      document.getElementById("lockInButton").style.visibility = "visible";
      document.getElementById("enterNotification").style.visibility = "visible";
      document.getElementById("guess").style.visibility = "visible";
      document.getElementById("guess").style.opacity = "100%";
      document.getElementById("guess1").style.visibility = "visible";
      document.getElementById("guess1").style.opacity = "100%";
      document.getElementById("waitingDiv").style.visibility = "visible"; 
    }
    
  }

  function moveUp(){
    if(isInGame === true){
      if(screenVar.matches){
        document.getElementById("sayTheSameThing").style.transform="scale(0)";
        document.getElementById("titleDiv").style.transform="scale(0.1%)";
        document.getElementById("playerCircle").style.transform="translateY(-95px)";
        document.getElementById("playerCircle").style.transform+="scale(0.88)";
        document.getElementById("nameDiv").style.transform="translateY(-100px)";
        document.getElementById("opponentCircle").style.transform="translateY(-95px)";
        document.getElementById("opponentCircle").style.transform+="scale(0.88)";
        document.getElementById("yourFriendsName").style.transform="translateY(-100px)";
        // setTimeout(enlargeSaySameThing, 50);
        setTimeout(makeFirstGuessVisible, 2000);
        
      }else{
        document.getElementById("playerCircle").style.transform="translateY(-150px)";
        document.getElementById("nameDiv").style.transform="translateY(-150px)";
        document.getElementById("opponentCircle").style.transform="translateY(-150px)";
        document.getElementById("yourFriendsName").style.transform="translateY(-150px)";
        // setTimeout(enlargeSaySameThing, 50);
        setTimeout(makeFirstGuessVisible, 2000);
        setTimeout(moveUpSaySameThing, 750);
      }
    
    }
  }

  function setOtherPlayerNameFiller(){
    if(opponentName !== ""){
    setRoomPlayersReceived(opponentName);  
    }else{
    setRoomPlayersReceived("Choosing a Name...");  
    }
    
  }

  function increaseHelp(){
    setHelpPage(helpPage+1);
  }

  function decreaseHelp(){
    setHelpPage(helpPage-1);
  }


  function incrementDown(){
    if(isInGame === true){
      document.getElementById("lockInButton").style.opacity = "50%"
      console.log(round);
    document.getElementById("lockInButton").style.visibility = "visible";
    if(round === 1){
      
      //second round - guess was being used
      document.getElementById("guess").style.transform="translateY(-155px)";
      document.getElementById("guess").style.backgroundColor="#FAA0A0";
      document.getElementById("guess").readOnly = true;
      document.getElementById("guess").style.opacity="75%";
      document.getElementById("m1").style.transform="translateY(-320px)";
      document.getElementById("m1").style.visibility = "visible";
      
      document.getElementById("m1").readOnly = false;
      document.getElementById("m2").style.transform="translateY(-485px)";
      document.getElementById("m3").style.transform="translateY(-650px)";
      document.getElementById("m4").style.transform="translateY(-815px)";
      document.getElementById("m5").style.transform="translateY(-980px)";
      document.getElementById("m6").style.transform="translateY(-1145px)";
      document.getElementById("m7").style.transform="translateY(-1310px)";
      document.getElementById("m8").style.transform="translateY(-1475px)";
      document.getElementById("m9").style.transform="translateY(-1640px)";
      document.getElementById("m10").style.transform="translateY(-1805px)";
      //
      document.getElementById("guess1").style.transform="translateY(-78px)";
      document.getElementById("guess1").style.backgroundColor="#FAA0A0";
      document.getElementById("guess1").style.opacity="75%";
      document.getElementById("o1").style.transform="translateY(-243px)";
      document.getElementById("o1").style.visibility = "visible";
      document.getElementById("o2").style.transform="translateY(-408px)";
      document.getElementById("o3").style.transform="translateY(-573px)";
      document.getElementById("o4").style.transform="translateY(-738px)";
      document.getElementById("o5").style.transform="translateY(-903px)";
      document.getElementById("o6").style.transform="translateY(-1068px)";
      document.getElementById("o7").style.transform="translateY(-1233px)";
      document.getElementById("o8").style.transform="translateY(-1398px)";
      document.getElementById("o9").style.transform="translateY(-1563px)";
      document.getElementById("o10").style.transform="translateY(-1728px)";

    }else if(round === 2){
      //third round - m1 was being used
      document.getElementById("guess").style.transform="translateY(-75px)";
      document.getElementById("m1").style.transform="translateY(-240px)";
      document.getElementById("m1").style.backgroundColor="#FAA0A0";
      document.getElementById("m1").readOnly = true;
      document.getElementById("m1").style.opacity="75%";
      document.getElementById("m2").style.transform="translateY(-405px)";
      document.getElementById("m2").style.visibility = "visible";
      document.getElementById("m2").readOnly = false;
      if(screenVar.matches){
        document.getElementById("guess").style.opacity = "0%";
      }
      document.getElementById("m3").style.transform="translateY(-570px)";
      document.getElementById("m4").style.transform="translateY(-735px)";
      document.getElementById("m5").style.transform="translateY(-900px)";
      document.getElementById("m6").style.transform="translateY(-1065px)";
      document.getElementById("m7").style.transform="translateY(-1230px)";
      document.getElementById("m8").style.transform="translateY(-1395px)";
      document.getElementById("m9").style.transform="translateY(-1560px)";
      document.getElementById("m10").style.transform="translateY(-1725px)";
      //
      document.getElementById("guess1").style.transform="translateY(2px)";
      document.getElementById("o1").style.transform="translateY(-163px)";
      document.getElementById("o1").style.backgroundColor="#FAA0A0";
      document.getElementById("o1").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("guess1").style.opacity = "0%";
      }
      document.getElementById("o2").style.transform="translateY(-328px)";
      document.getElementById("o2").style.visibility = "visible";
      document.getElementById("o3").style.transform="translateY(-493px)";
      document.getElementById("o4").style.transform="translateY(-658px)";
      document.getElementById("o5").style.transform="translateY(-823px)";
      document.getElementById("o6").style.transform="translateY(-988px)";
      document.getElementById("o7").style.transform="translateY(-1153px)";
      document.getElementById("o8").style.transform="translateY(-1318px)";
      document.getElementById("o9").style.transform="translateY(-1483px)";
      document.getElementById("o10").style.transform="translateY(-1648px)";
    }else if(round === 3){
      //fourth round - m2 was being used
      document.getElementById("guess").style.transform="translateY(5px)";
      document.getElementById("m1").style.transform="translateY(-160px)";
      document.getElementById("m2").style.transform="translateY(-325px)";
      document.getElementById("m2").style.backgroundColor="#FAA0A0";
      document.getElementById("m2").readOnly = true;
      document.getElementById("m2").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("m1").style.opacity = "0%";
      }
      document.getElementById("m3").style.transform="translateY(-490px)";
      document.getElementById("m3").style.visibility = "visible";
      document.getElementById("m3").readOnly = false;
      document.getElementById("m4").style.transform="translateY(-655px)";
      document.getElementById("m5").style.transform="translateY(-820px)";
      document.getElementById("m6").style.transform="translateY(-985px)";
      document.getElementById("m7").style.transform="translateY(-1150px)";
      document.getElementById("m8").style.transform="translateY(-1315px)";
      document.getElementById("m9").style.transform="translateY(-1480px)";
      document.getElementById("m10").style.transform="translateY(-1645px)";
      //
      document.getElementById("guess1").style.transform="translateY(82px)";
      document.getElementById("o1").style.transform="translateY(-83px)";
      document.getElementById("o2").style.backgroundColor="#FAA0A0";
      document.getElementById("o2").style.opacity="75%";
      document.getElementById("o2").style.transform="translateY(-248px)";
      document.getElementById("o3").style.transform="translateY(-413px)";
      document.getElementById("o3").style.visibility = "visible";
      if(screenVar.matches){
        document.getElementById("o1").style.opacity = "0%";
      }
      document.getElementById("o4").style.transform="translateY(-578px)";
      document.getElementById("o5").style.transform="translateY(-743px)";
      document.getElementById("o6").style.transform="translateY(-908px)";
      document.getElementById("o7").style.transform="translateY(-1073px)";
      document.getElementById("o8").style.transform="translateY(-1238px)";
      document.getElementById("o9").style.transform="translateY(-1403px)";
      document.getElementById("o10").style.transform="translateY(-1568px)";
    }else if(round === 4){
      //fourth round - m3 was being used
      document.getElementById("guess").style.transform="translateY(85px)";
      document.getElementById("m1").style.transform="translateY(-80px)";
      document.getElementById("m2").style.transform="translateY(-245px)";
      document.getElementById("m3").style.transform="translateY(-410px)";
      document.getElementById("m3").style.backgroundColor="#FAA0A0";
      document.getElementById("m3").readOnly = true;
      document.getElementById("m3").style.opacity="75%";
      document.getElementById("m4").style.transform="translateY(-575px)";
      document.getElementById("m4").style.visibility = "visible";
      document.getElementById("m4").readOnly = false;
      if(screenVar.matches){
        document.getElementById("m2").style.opacity = "0%";
      }
      document.getElementById("guess").style.opacity="0%";
      
      document.getElementById("m5").style.transform="translateY(-740px)";
      document.getElementById("m6").style.transform="translateY(-905px)";
      document.getElementById("m7").style.transform="translateY(-1070px)";
      document.getElementById("m8").style.transform="translateY(-1235px)";
      document.getElementById("m9").style.transform="translateY(-1400px)";
      document.getElementById("m10").style.transform="translateY(-1565px)";
      //
      document.getElementById("guess1").style.transform="translateY(162px)";
      document.getElementById("o1").style.transform="translateY(-3px)";
      document.getElementById("o2").style.transform="translateY(-168px)";
      document.getElementById("o3").style.transform="translateY(-333px)";
      document.getElementById("o3").style.backgroundColor="#FAA0A0";
      document.getElementById("o3").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("o2").style.opacity = "0%";
      }
      document.getElementById("o4").style.transform="translateY(-498px)";
      document.getElementById("o4").style.visibility = "visible";
      document.getElementById("guess1").style.opacity="0%";
      document.getElementById("o5").style.transform="translateY(-663px)";
      document.getElementById("o6").style.transform="translateY(-828px)";
      document.getElementById("o7").style.transform="translateY(-993px)";
      document.getElementById("o8").style.transform="translateY(-1158px)";
      document.getElementById("o9").style.transform="translateY(-1323px)";
      document.getElementById("o10").style.transform="translateY(-1488px)";
    }else if(round === 5){
      //fourth round - m4 was being used
      document.getElementById("guess").style.transform="translateY(165px)";
      document.getElementById("m1").style.transform="translateY(-0px)";
      document.getElementById("m2").style.transform="translateY(-165px)";
      document.getElementById("m3").style.transform="translateY(-330px)";
      document.getElementById("m4").style.transform="translateY(-495px)";
      document.getElementById("m4").style.backgroundColor="#FAA0A0";
      document.getElementById("m4").readOnly = true;
      if(screenVar.matches){
        document.getElementById("m3").style.opacity = "0%";
      }
      document.getElementById("m4").style.opacity="75%";
      document.getElementById("m5").style.transform="translateY(-660px)";
      document.getElementById("m5").style.visibility = "visible";
      document.getElementById("m5").readOnly = false;
      
      document.getElementById("m1").style.opacity="0%";
      document.getElementById("m6").style.transform="translateY(-825px)";
      document.getElementById("m7").style.transform="translateY(-990px)";
      document.getElementById("m8").style.transform="translateY(-1155px)";
      document.getElementById("m9").style.transform="translateY(-1320px)";
      document.getElementById("m10").style.transform="translateY(-1485px)";
      //
      document.getElementById("guess1").style.transform="translateY(242px)";
      document.getElementById("o1").style.transform="translateY(77px)";
      document.getElementById("o2").style.transform="translateY(-88px)";
      document.getElementById("o3").style.transform="translateY(-253px)";
      document.getElementById("o4").style.transform="translateY(-418px)";
      document.getElementById("o4").style.backgroundColor="#FAA0A0";
      document.getElementById("o4").style.opacity="75%";
      document.getElementById("o5").style.transform="translateY(-583px)";
      document.getElementById("o5").style.visibility = "visible";
      document.getElementById("o1").style.opacity="0%";
      if(screenVar.matches){
        document.getElementById("o3").style.opacity = "0%";
      }
      document.getElementById("o6").style.transform="translateY(-748px)";
      document.getElementById("o7").style.transform="translateY(-913px)";
      document.getElementById("o8").style.transform="translateY(-1078px)";
      document.getElementById("o9").style.transform="translateY(-1243px)";
      document.getElementById("o10").style.transform="translateY(-1408px)";
    }else if(round === 6){
      //fourth round - m5 was being used
      document.getElementById("guess").style.transform="translateY(245px)";
      document.getElementById("m1").style.transform="translateY(80px)";
      document.getElementById("m2").style.transform="translateY(-85px)";
      document.getElementById("m3").style.transform="translateY(-250px)";
      document.getElementById("m4").style.transform="translateY(-415px)";
      document.getElementById("m5").style.transform="translateY(-580px)";
      document.getElementById("m5").style.backgroundColor="#FAA0A0";
      document.getElementById("m5").readOnly = true;
      document.getElementById("m5").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("m4").style.opacity = "0%";
      }
      document.getElementById("m6").style.transform="translateY(-745px)";
      document.getElementById("m6").style.visibility = "visible";
      document.getElementById("m6").readOnly = false;
      document.getElementById("m2").style.opacity="0%";
      document.getElementById("m7").style.transform="translateY(-910px)";
      document.getElementById("m8").style.transform="translateY(-1075px)";
      document.getElementById("m9").style.transform="translateY(-1240px)";
      document.getElementById("m10").style.transform="translateY(-1405px)";
      //
      document.getElementById("guess1").style.transform="translateY(322px)";
      document.getElementById("o1").style.transform="translateY(157px)";
      document.getElementById("o2").style.transform="translateY(-8px)";
      document.getElementById("o3").style.transform="translateY(-173px)";
      document.getElementById("o4").style.transform="translateY(-338px)";
      document.getElementById("o5").style.transform="translateY(-503px)";
      document.getElementById("o5").style.backgroundColor="#FAA0A0";
      document.getElementById("o5").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("o4").style.opacity = "0%";
      }
      document.getElementById("o6").style.transform="translateY(-668px)";
      document.getElementById("o6").style.visibility = "visible";
      document.getElementById("o2").style.opacity="0%";
      document.getElementById("o7").style.transform="translateY(-833px)";
      document.getElementById("o8").style.transform="translateY(-998px)";
      document.getElementById("o9").style.transform="translateY(-1163px)";
      document.getElementById("o10").style.transform="translateY(-1328px)";
    }else if(round === 7){
      //fourth round - m6 was being used
      document.getElementById("guess").style.transform="translateY(325px)";
      document.getElementById("m1").style.transform="translateY(160px)";
      document.getElementById("m2").style.transform="translateY(-5px)";
      document.getElementById("m3").style.transform="translateY(-170px)";
      document.getElementById("m4").style.transform="translateY(-335px)";
      document.getElementById("m5").style.transform="translateY(-500px)";
      document.getElementById("m6").style.transform="translateY(-665px)";
      document.getElementById("m6").style.backgroundColor="#FAA0A0";
      document.getElementById("m6").readOnly = true;
      if(screenVar.matches){
        document.getElementById("m5").style.opacity = "0%";
      }
      document.getElementById("m6").style.opacity="75%";
      document.getElementById("m7").style.transform="translateY(-830px)";
      document.getElementById("m7").style.visibility = "visible";
      document.getElementById("m7").readOnly = false;
      document.getElementById("m3").style.opacity="0%";
      document.getElementById("m8").style.transform="translateY(-995px)";
      document.getElementById("m9").style.transform="translateY(-1160px)";
      document.getElementById("m10").style.transform="translateY(-1325px)";
      //
      document.getElementById("guess1").style.transform="translateY(402px)";
      document.getElementById("o1").style.transform="translateY(237px)";
      document.getElementById("o2").style.transform="translateY(72px)";
      document.getElementById("o3").style.transform="translateY(-93px)";
      document.getElementById("o4").style.transform="translateY(-258px)";
      document.getElementById("o5").style.transform="translateY(-423px)";
      document.getElementById("o6").style.transform="translateY(-588px)";
      document.getElementById("o6").style.backgroundColor="#FAA0A0";
      document.getElementById("o6").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("o5").style.opacity = "0%";
      }
      document.getElementById("o7").style.transform="translateY(-753px)";
      document.getElementById("o7").style.visibility = "visible";
      document.getElementById("o3").style.opacity="0%";
      document.getElementById("o8").style.transform="translateY(-918px)";
      document.getElementById("o9").style.transform="translateY(-1083px)";
      document.getElementById("o10").style.transform="translateY(-1248px)";
    }else if(round === 8){
      //fourth round - m7 was being used
      document.getElementById("guess").style.transform="translateY(405px)";
      document.getElementById("m1").style.transform="translateY(240px)";
      document.getElementById("m2").style.transform="translateY(75px)";
      document.getElementById("m3").style.transform="translateY(-90px)";
      document.getElementById("m4").style.transform="translateY(-255px)";
      document.getElementById("m5").style.transform="translateY(-420px)";
      document.getElementById("m6").style.transform="translateY(-585px)";
      document.getElementById("m7").style.transform="translateY(-750px)";
      document.getElementById("m7").style.backgroundColor="#FAA0A0";
      document.getElementById("m7").readOnly = true;
      document.getElementById("m7").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("m6").style.opacity = "0%";
      }
      document.getElementById("m8").style.transform="translateY(-915px)";
      document.getElementById("m8").style.visibility = "visible";
      document.getElementById("m8").readOnly = false;
      document.getElementById("m4").style.opacity="0%";
      document.getElementById("m9").style.transform="translateY(-1080px)";
      document.getElementById("m10").style.transform="translateY(-1245px)";
      //
      document.getElementById("guess1").style.transform="translateY(482px)";
      document.getElementById("o1").style.transform="translateY(317px)";
      document.getElementById("o2").style.transform="translateY(152px)";
      document.getElementById("o3").style.transform="translateY(-13px)";
      document.getElementById("o4").style.transform="translateY(-178px)";
      document.getElementById("o5").style.transform="translateY(-343px)";
      document.getElementById("o6").style.transform="translateY(-508px)";
      document.getElementById("o7").style.transform="translateY(-673px)";
      document.getElementById("o7").style.backgroundColor="#FAA0A0";
      document.getElementById("o7").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("o6").style.opacity = "0%";
      }
      document.getElementById("o8").style.transform="translateY(-838px)";
      document.getElementById("o8").style.visibility = "visible";
      document.getElementById("o4").style.opacity="0%";
      document.getElementById("o9").style.transform="translateY(-1003px)";
      document.getElementById("o10").style.transform="translateY(-1168px)";
      
    }
    else if(round === 9){
      //fourth round - m8 was being used
      document.getElementById("guess").style.transform="translateY(485px)";
      document.getElementById("m1").style.transform="translateY(320px)";
      document.getElementById("m2").style.transform="translateY(155px)";
      document.getElementById("m3").style.transform="translateY(-10px)";
      document.getElementById("m4").style.transform="translateY(-175px)";
      document.getElementById("m5").style.transform="translateY(-340px)";
      document.getElementById("m6").style.transform="translateY(-505px)";
      document.getElementById("m7").style.transform="translateY(-670px)";
      document.getElementById("m8").style.transform="translateY(-835px)";
      document.getElementById("m8").style.backgroundColor="#FAA0A0";
      document.getElementById("m8").readOnly = true;
      if(screenVar.matches){
        document.getElementById("m7").style.opacity = "0%";
      }
      document.getElementById("m8").style.opacity="75%";
      document.getElementById("m9").style.transform="translateY(-1000px)";
      document.getElementById("m9").style.visibility = "visible";
      document.getElementById("m9").readOnly = false;
      document.getElementById("m5").style.opacity="0%";
      document.getElementById("m10").style.transform="translateY(-1165px)";
      //
      document.getElementById("guess1").style.transform="translateY(562px)";
      document.getElementById("o1").style.transform="translateY(397px)";
      document.getElementById("o2").style.transform="translateY(232px)";
      document.getElementById("o3").style.transform="translateY(67px)";
      document.getElementById("o4").style.transform="translateY(-98px)";
      document.getElementById("o5").style.transform="translateY(-263px)";
      document.getElementById("o6").style.transform="translateY(-428px)";
      document.getElementById("o7").style.transform="translateY(-593px)";
      document.getElementById("o8").style.transform="translateY(-758px)";
      document.getElementById("o8").style.backgroundColor="#FAA0A0";
      document.getElementById("o8").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("o7").style.opacity = "0%";
      }
      document.getElementById("o9").style.transform="translateY(-923px)";
      document.getElementById("o9").style.visibility = "visible";
      document.getElementById("o5").style.opacity="0%";
      document.getElementById("o10").style.transform="translateY(-1088px)";
    }else if(round === 10){
      //fourth round - m9 was being used
      document.getElementById("guess").style.transform="translateY(565px)";
      document.getElementById("m1").style.transform="translateY(400px)";
      document.getElementById("m2").style.transform="translateY(235px)";
      document.getElementById("m3").style.transform="translateY(70px)";
      document.getElementById("m4").style.transform="translateY(-95px)";
      document.getElementById("m5").style.transform="translateY(-260px)";
      document.getElementById("m6").style.transform="translateY(-425px)";
      document.getElementById("m7").style.transform="translateY(-590px)";
      document.getElementById("m8").style.transform="translateY(-755px)";
      document.getElementById("m9").style.transform="translateY(-920px)";
      document.getElementById("m9").style.backgroundColor="#FAA0A0";
      document.getElementById("m9").readOnly = true;
      document.getElementById("m9").style.opacity="75%";
      if(screenVar.matches){
        document.getElementById("m8").style.opacity = "0%";
      }
      document.getElementById("m10").style.transform="translateY(-1085px)";
      document.getElementById("m10").style.visibility = "visible";
      document.getElementById("m6").style.opacity="0%";
      document.getElementById("m10").readOnly = false;
      //
      document.getElementById("guess1").style.transform="translateY(642px)";
      document.getElementById("o1").style.transform="translateY(477px)";
      document.getElementById("o2").style.transform="translateY(312px)";
      document.getElementById("o3").style.transform="translateY(147px)";
      document.getElementById("o4").style.transform="translateY(-18px)";
      document.getElementById("o5").style.transform="translateY(-183px)";
      document.getElementById("o6").style.transform="translateY(-348px)";
      document.getElementById("o7").style.transform="translateY(-513px)";
      document.getElementById("o8").style.transform="translateY(-678px)";
      document.getElementById("o9").style.transform="translateY(-843px)";
      if(screenVar.matches){
        document.getElementById("o8").style.opacity = "0%";
      }
      document.getElementById("o9").style.backgroundColor="#FAA0A0";
      document.getElementById("o9").style.opacity="75%";
      document.getElementById("o10").style.transform="translateY(-1008px)";
      document.getElementById("o6").style.opacity="0%";
      document.getElementById("o10").style.visibility = "visible";
      
    }else if(round === 11){
      //fourth round - m10 was being used
      // document.getElementById("guess").style.transform="translateY(645px)";
      // document.getElementById("m1").style.transform="translateY(480px)";
      // document.getElementById("m2").style.transform="translateY(315px)";
      // document.getElementById("m3").style.transform="translateY(150px)";
      // document.getElementById("m4").style.transform="translateY(-15px)";
      // document.getElementById("m5").style.transform="translateY(-180px)";
      // document.getElementById("m6").style.transform="translateY(-345px)";
      // document.getElementById("m7").style.transform="translateY(-510px)";
      // document.getElementById("m8").style.transform="translateY(-675px)";
      // document.getElementById("m9").style.transform="translateY(-840px)";
      //document.getElementById("m10").style.transform="translateY(-1005px)";
      document.getElementById("m10").style.backgroundColor="#FAA0A0";
      document.getElementById("m10").readOnly = true;
      document.getElementById("lockInButton").style.visibility = "hidden";
      
      
      //
      // document.getElementById("guess1").style.transform="translateY(642px)";
      // document.getElementById("o1").style.transform="translateY(477px)";
      // document.getElementById("o2").style.transform="translateY(312px)";
      // document.getElementById("o3").style.transform="translateY(147px)";
      // document.getElementById("o4").style.transform="translateY(-18px)";
      // document.getElementById("o5").style.transform="translateY(-183px)";
      // document.getElementById("o6").style.transform="translateY(-348px)";
      // document.getElementById("o7").style.transform="translateY(-513px)";
      // document.getElementById("o8").style.transform="translateY(-678px)";
      // document.getElementById("o9").style.transform="translateY(-843px)";
      //document.getElementById("o10").style.transform="translateY(-1008px)";
      document.getElementById("o10").style.backgroundColor="#FAA0A0";
    }
    
    
    // translate-y-[-155px] translate-y-[-75px] translate-y-[5px]
    }
    
    
    
  }

  function generateCode(){
    let lengthCode = 6;
    let result = '';
    const characters =
    "0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < lengthCode; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    code = result.toUpperCase();
    console.log(code);
    socket.emit("join_room", code, "create");
    console.log("Sending application to Create Room " + code);    
    navigator.clipboard.writeText(code);
    
    //visual changes
    document.getElementById("generateCodeButton").style.backgroundColor = "#ABFFDC";
    document.getElementById("generateCodeButton").style.color = "black";
    document.getElementById("generateCodeButton").style.borderWidth = "3px";
    document.getElementById("generateCodeButton").style.borderColor = "black";
    document.getElementById("generateCodeButton").innerText = code;
    document.getElementById("clipBoardNotif").style.opacity = 100;
    setTimeout(makeNotifInvisible, 5000)
  }

  function makeNotifInvisible(){
    document.getElementById("clipBoardNotif").style.opacity = 0;
  }

  const join_room = () => {
    //THIS SENDS ROOM DATA TO BACKEND
    if ((room !== "") && (room !== "0")){
      socket.emit("join_room", room);
      console.log("Sending application for Room: " + room, "join");
    }
  };

  // const sendReady = () => {
  //   THIS WILL TELL THE SERVER THIS USER IS READY TO PLAY : )
  //   unused rn
  //   socket.emit("send_message", ready);
  // };

  const sendStartGameFunc = () => {
    socket.emit("start", sendStartGame);
  }

  const sendMessage = (tempMessage) => {
    
    console.log("sending message: " + tempMessage);
    socket.emit("send_message", {tempMessage, room});
    //THIS EMITS DATA TO THE BACKEND ^^^
  };

  function hasWhiteSpace(s) {
    return s.indexOf(' ') >= 0;
  }

  const sendName = () => {
    if(hasWhiteSpace(yourName) != true){
    socket.emit("send_name", yourName, playerSelection);  
    }

    //THIS EMITS NAME TO THE BACKEND ^^^
  };

  const actualStartGame = () =>{
    socket.emit("start_rounds", null);
  }


  useEffect(() => {
    //THIS IS LISTENING FOR THE BACKEND TO SEND THE MESSAGE
    socket.on("receive_message", (data, userID) => {
      if(isInGame){
        //THIS IS WHAT WE DO WITH THE DATA
      console.log("Received Message" + data + " from user: " + userID);
        if(userID == permUserIdentification){
        
        messages[round-1] = data; 

        }else{
          messagesReceived[round-1] = data;  
          if(round == 1){
            document.getElementById("guess1").value = data;  
          }else{
            let temp3 = "o" + (round-1).toString();
            document.getElementById(temp3).value = data;
          }
        }
      console.log("My Messages: " + messages);
      console.log("Other Players Messages: " + messagesReceived);
      
      
      }
      

      
      
    });


    socket.on("receive_restart_request", (data) => {
      document.getElementById("guess1").style.visibility="hidden";
      document.getElementById("guess").style.visibility="hidden";
      document.getElementById("playAgainButton").style.transform+="scale(0)";
      let tempM1 = "m";
    let tempO1 = "o";
    for(let i = 1; i<11; i++){
      tempM1 = "m" + i.toString();
      tempO1 = "o" + i.toString();
      document.getElementById(tempM1).style.visibility="hidden";
      document.getElementById(tempO1).style.visibility="hidden";
    }
      setTimeout(restartGame,500);
    })


    socket.on("receive_name", (data) => {
      //THIS IS WHAT WE DO WITH THE DATA
      if(data !== ""){
      document.getElementById("nameSendButton").style.display = "none";
      document.getElementById("nameSendInput").style.display = "none";  
      document.getElementById("leftArrowButton").style.display = "none";
      document.getElementById("rightArrowButton").style.display = "none";  
      }
      setNameReceived(data);
    })
    
    //Receiving user id
    socket.on("give_id", (data) => {
      //THIS IS WHAT WE DO WITH THE DATA
      permUserIdentification = data;
    })

    socket.on("receive_room", (data) => {
      if(data !== "full"){
      console.log("Connected to Room " + data);
      setRoomReceived(data);  
       
      }else{
      setRoomReceived("Room is Full!");
      }
      
    })




    socket.on("receive_timer", (data1) => {
      setGameTime(data1);
    })

    

    socket.on("receive_wave", (data1) => {
      if(isInGame){
      document.getElementById("enterNotification").style.visibility = "hidden";
      document.getElementById("lockInButton").style.visibility="hidden";
      let x = "";
      if(messages.length === 0){
        x = document.getElementById("guess").value;
        document.getElementById("guess").readOnly = true;
        
      }else{
        let temp = "";
        temp = "m" + (messages.length).toString();
        console.log("temp = " + temp);
        x = document.getElementById(temp).value;
        document.getElementById(temp).readOnly = true;
        
      }
      
      if((x === "") || (hasWhiteSpace(x))){
        x = createRandomWord();
        if(messages.length !== 0){
        let temp2 = "m" + (messages.length).toString();
        document.getElementById(temp2).value = x;
        document.getElementById(temp2).readOnly = true;   
        }else{
        document.getElementById("guess").value = x;
        document.getElementById("guess").readOnly = true;       
        }
        
      }
      console.log(x);

      if(messages.length === 0){
        document.getElementById("guess").style.backgroundColor = "#DFDFDF";
      }
      else if(messages.length !== 0){
        let temp1 = "";
        temp1 = "m" + (messages.length).toString();
        document.getElementById(temp1).style.backgroundColor = "#DFDFDF";
      }

      x = x.toUpperCase();
      sendMessage(x);
      round = data1;
      console.log("Round: " + round);
      
      if(hasWon === false){
      //setTimeout(incrementDown, 3500);  
      }
    }
    })

    socket.on("receive_continue", (data1) => {
      setTimeout(incrementDown, 3500);
      
    })

    socket.on("receive_players", (data1, fighterSelection) => {
      console.log("Receiving the list of players: " + data1);
      opponentName = data1;
      console.log(fighterSelection);
      setOponnentSelection(fighterSelection);
      setTimeout(setOtherPlayerNameFiller, 30)
    })

    socket.on("receive_disconnect", (data1) => {
      isInGame = false;
      socket.emit("send_leave", null);
      restartGame();
      opponentName = "Disconnected"
      setRoomPlayersReceived(opponentName);
      setTimeout(generateCodeButtonFunction, 3000)
      document.getElementById("submitRoomButton").style.display = "block";
      document.getElementById("enterNotification").style.visibility = "hidden";
      document.getElementById("startBtn").style.scale = "0";
      document.getElementById("roomNumBox").style.display = "block";
      document.getElementById("roomNumBox").value = "";
      setInGame(false);
      
    })

    socket.on("begin", (data) => {
      setInGame(true);
      isInGame = true;
      document.getElementById("startBtn").style.scale = "0";
      
      
        
      var i = 5;  //set the countdown
      (function timer(){
          if (--i < 0) return;
          setTimeout(function(){
            if(isInGame !== true){
              return;
            }
            setTimer(i);  //do stuff here
            if(i == 0){
              isInGame = true;
              actualStartGame();
              setTimeout(moveUp, 1500);
            }
            timer();
        }, 1000);
        })();
    })

    socket.on("receive_ready", (data) => {
      if(data === "ready"){
        document.getElementById("generateCodeButton").style.display = "none"; 
        document.getElementById("clipBoardNotif").style.display = "none";  
        document.getElementById("submitRoomButton").style.display = "none";
        document.getElementById("roomNumBox").style.display = "none";
        //document.getElementById("startBtn").style.transform="";
        let btn = document.getElementById("startBtn")
        //btn.style.backgroundColor = "red";
        btn.style.opacity = 100;
        btn.style.scale = 1;
        
      }else if(data === "notReadyButInLobby"){
        document.getElementById("generateCodeButton").style.display = "none"; 
        document.getElementById("clipBoardNotif").style.display = "none";  
        document.getElementById("submitRoomButton").style.display = "none";
        document.getElementById("roomNumBox").style.display = "none";
      }else{
        setReadyReceieved(""); 
        let btn = document.getElementById("startBtn")
        btn.style.scale = 0;
      }
      
    })

    
    socket.on("receive_startup", (data) => {
      mainMenuFunc();

    })


    socket.on("receive_loss", (data1) => {
      loseFunc();
    })

    socket.on("receive_win", (data1) => {
      //alert("Jinx, you owe me a soda! You both thought of " + data1);
      winFunc(data1);

    })

  }, [socket])

  return (

<div className="App fixed m-0 w-screen h-screen md:bg-gradient-to-r md:from-[#5BACF6] md:from-[50%] md:to-[#FF993A] md:to-[50%] 
      bg-gradient-to-t from-[#FF993A] from-[53%] to-[#5BACF6] to-[53%] bg-cover">
      {(timer !== 0 && (inGame == true)) && (<h2 className='absolute md:mt-[250px] md:text-[50px] md:text-[40px] text-[28px] left-[10%] right-[10%] md:top-[11%] top-[45%]
      transition-all duration-[700ms]'>Game will begin in...</h2>)}
      <div className="buttonBuffer mx-full md:h-1 h-[1px]" ></div>
      {(timer !== 0 && (inGame == true)) && (<h2 className='absolute md:mt-[250px] md:text-[100px]  md:text-[80px] text-[60px] left-1/3 right-1/3 md:top-[15%] top-[48%] transition-all duration-[700ms]
      lagging-none'>{timer}</h2>)}
      {(timer == 0 && (inGame == true)) && (<h2 id="sayTheSameThing" className='fixed md:mt-[250px] md:text-[50px] text-[30px] left-[10%] md:top-[140px] top-[45%] right-[10%] transition-all duration-[700ms]'>Type the same thing!</h2>)}
      <h8>{messageReceived}</h8>
  <div id="playerContainer" className='playercontainer fixed md:top-0 md:bottom-0 md:left-0 md:right-1/2 md:w-1/2 md:h-screen 
  top-0 left-0 right-0 bottom-1/2 box-border inline-block justify-center items-center z-0 transition-all duration-[700ms] scale-0'>
        <div className="buffer mx-full md:h-1/4 h-[22%]" ></div>
          <div id="playerCircle" className="transition-all duration-[700ms] circle rounded-full bg-white md:border-[4px] md:w-[225px] md:h-[225px] w-[120px] h-[120px] border-[3px] 
          inline-block md:my-3 my-0 border-black "><img src={fightersBlack[playerSelection]} 
          className='scale-[86%] translate-x-[6px] translate-y-[6px]'></img></div>
        <h5 id="nameDiv" className="transition-all duration-[700ms] bg-transparent md:text-7xl text-[35px] w-full md:mb-5 mb-2 leading-none">{yourNameReceived}</h5>
          <input id="nameSendInput" className="outline-0 md:mb-3 md:border-[4px] md:w-[420px] md:h-[69px] md:text-[30px]  border-[3px] text-[23px] w-[280px] h-[55px] 
          mb-0 bg-white rounded-[60px] text-center text-black  mx-full  
          border-black  md:hover:border-[5px]" onPaste={(event) => {event.preventDefault()}} onKeyDown={(event) => {if(event.key === " "){event.preventDefault()
          }}} placeholder='Choose a Name...' onChange={(event) => {setYourName(event.target.value);}}/>
        <div className="buttonBuffer mx-full h-1" ></div>
          <button id="nameSendButton" className="md:text-[30px] md:w-[420px] md:h-[69px] text-[23px] w-[280px] h-[55px] bg-black rounded-[60px]  text-white  mx-auto my-1 transition-all 
          duration-[400ms] hover:opacity-75" onClick={sendName}>Submit</button>
        <div className="buttonBuffer mx-full h-1" ></div>
          <button id="lockInButton"className="fixed md:w-[65px] md:h-[65px] md:left-0 md:right-[65%] md:ml-[71%] md:mr-[7.5%] rounded-full text-center md:text-[35px] 
          w-[60px] h-[60px] left-[85%] text-[30px] bg-black text-white
          invisible md:translate-y-[-170px] translate-y-[-108px] opacity-50" onClick={sendLockIn}>➜</button>
        <div className="buttonBuffer mx-full h-1" ></div>
          <div className="buttonBuffer mx-full md:h-[60px] h-[120px]" ></div>
              <input id="guess" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} 
              className="outline-0 md:text-[30px] text-[25px] bg-white rounded-[60px] md:border-[4px] border-[3px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 
              border-black md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-235px] uppercase invisible" placeholder='Enter your word' 
              onChange={checkSubmitButton}/>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m1" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px]  text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 
              transition-all duration-[400ms] translate-y-[-400px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m2" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-565px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m3" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-730px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m4" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-895px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m5" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-1060px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m6" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-1225px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m7" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-1390px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m8" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-1555px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m9" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] h-[60px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-1720px] uppercase invisible' onChange={checkSubmitButton}></input>
              <div className="buttonBuffer mx-full h-1" ></div>
              <input id="m10" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()} else if(event.key === "Enter"){sendLockIn()}}} onPaste={(event) => {event.preventDefault()}} readOnly="true" className='outline-0 
              md:text-[30px] text-[25px] bg-white rounded-[60px] md:w-[40%] h-[69px] w-[68%] h-[60px] text-center text-black mb-3 mx-[5%] border-black border-[4px] md:hover:border-5 transition-all
              duration-[400ms] translate-y-[-1885px] uppercase invisible' onChange={checkSubmitButton}></input>
            <div className="buttonBuffer mx-full h-1" ></div>
          <button id="leftArrowButton" className="absolute md:border-[4px] md:text-[25px] md:w-[50px] md:h-[50px]  text-[17px] w-[31px] h-[31px] border-[3px] leading-none
          bg-white rounded-full  text-black  mx-auto my-1 transition-all 
          duration-[400ms] hover:opacity-75 md:translate-y-[-1340px] md:translate-x-[125px] translate-y-[-1280px] translate-x-[75px] border-black " onClick={imgRight}>&gt;</button>
            <div className="buttonBuffer mx-full h-1" ></div>
          <button id="rightArrowButton" className="absolute md:border-[4px] md:w-[50px] md:h-[50px] md:text-[25px]  text-[17px] w-[31px] h-[31px] border-[3px] 
          bg-white rounded-full  text-black  mx-auto my-1 transition-all 
          duration-[400ms] hover:opacity-75 md:translate-y-[-1345px] md:translate-x-[-175px] translate-y-[-1285px] translate-x-[-105px] border-black leading-none" onClick={imgLeft}>&lt;</button>  
          <div className="buttonBuffer mx-full h-1" ></div>
          <div id="enterNotification" className="absolute md:text-[25px]  text-[17px] w-full
          bg-transparent rounded-full  text-black  mx-auto my-1 transition-all 
          duration-[400ms] opacity-85 md:translate-y-[-1105px] md:translate-x-[0px] translate-y-[-1105px] translate-x-[-0px] border-black leading-none invisible">Click or press 'enter' to submit...</div>
  </div>
  <div id="opponentContainer" className='opponentcontainer fixed md:top-0 md:bottom-0 md:left-1/2 md:right-0 md:w-1/2 md:h-screen 
  top-1/2 left-0 right-0 bottom-0 box-border inline-block justify-center items-center transition-all duration-[700ms] scale-0'>
      <button id="submitRoomButton" className="fixed md:top-[125px] md:right-[100px] md:w-[125px] md:h-[60px] 
      rounded-full text-center md:text-[30px]  text-[23px] w-[90px] h-[55px] top-[15px] right-[13%] bg-black text-white" onClick={join_room}>Join</button>
        <div className="buffer mx-full h-1/4" ></div>
      <div id="opponentCircle" className="transition-all duration-[700ms] circle rounded-full bg-black md:my-3 md:w-[225px] md:h-[225px] w-[140px] h-[140px] 
      inline-block my-0"><img src={fightersWhite[opponentSelection]} className='scale-[86%] translate-x-[-6px] translate-y-[6px]'></img></div>
        <h6 id="yourFriendsName" className="transition-all duration-[700ms] bg-transparent md:text-7xl  text-[35px] mb-[7px] leading-none">{roomPlayersReceived}</h6>
      <button id="generateCodeButton" className="hover:color-black md:text-[30px]  text-[23px] bg-black rounded-[60px] md:mt-3 md:w-[420px] md:h-[69px] 
      w-[280px] h-[55px] text-white 
      mx-auto transition-all duration-[400ms] hover:opacity-[90%]" onClick={generateCode}>Generate Friend Code</button>
        <input id="roomNumBox" onKeyDown={(event) => {if(event.key === " "){event.preventDefault()}}} className="outline-0 fixed md:border-[4px] md:top-[50px] md:right-[100px] md:w-[280px] md:h-[64px] 
        rounded-full text-center md:text-[30px] text-[23px] top-[15px] right-[40%] w-[200px] h-[55px] border-[3px] border-black  md:hover:border-[5px]" placeholder="Friend Code..." pattern="[A-Z]*" maxLength="6"
        onChange={(event) => {setRoom(event.target.value);}}/>
      <div className="buttonBuffer mx-full h-1" ></div>
        <p1 id="clipBoardNotif"className="opacity-0 transition-all duration-[400ms]">*Copied to clipboard</p1>
      <div className="buttonBuffer mx-full md:h-1 h-[50px]" ></div>
            <input id="guess1" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px]  text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px] 
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-158px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o1" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-323px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o2" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-488px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o3" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-653px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o4" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-818px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o5" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
           md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-983px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o6" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-1148px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o7" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-1313px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o8" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-1478px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o9" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-1643px] uppercase invisible'></input>
            <div className="buttonBuffer mx-full h-1" ></div>
            <input id="o10" placeholder="Thinking..." readOnly="true" className='outline-0 md:text-[30px] text-[25px] bg-[#DFDFDF] placeholder-gray-600 rounded-[60px]
            md:w-[40%] w-[68%] h-[69px] text-center text-black mb-3 mx-[5%] border-black md:border-[4px] border-[3px] md:hover:border-[5px] transition-all duration-[400ms] translate-y-[-1808px] uppercase invisible'></input>
    </div>
        <div className="buttonBuffer mx-full h-1" ></div>
        <button id="startBtn" class ="absolute md:top-[60%] md:text-[30px] md:w-[20%] md:left-[40%] md:right-[40%] md:h-[70px] text-[23px] top-[46.5%] w-[80%] left-[10%] right-[10%] h-[55px] bg-black rounded-[40px] 
        text-white transition-all duration-[400ms] 
        hover:opacity-[80%]" onClick={sendStartGameFunc}>Start Game!</button>
      {(gameTime == "0" && (inGame == true)) && (<p1 id="waitingDiv" className="absolute text-[30px] left-1/3 right-1/3 bottom-[50px] invisible"></p1>)}
        {(gameTime !== "0" && (inGame == true)) && (<p1 id="remainingDiv" className="fixed md:text-[30px] text-[20px] left-[10%] right-[10%] md:bottom-[50px] bottom-[5px]
        lagging-none">Remaining Time: {gameTime}</p1>)}
      <h9 id="titleDiv" className="fixed text-center left-1/3 right-1/3 w-1/3 md:top-[10px] md:text-[100px] top-[10px] text-[50px] transition-all duration-[400ms] scale-[0.1%]">Jinx!</h9>
        <input readOnly="true" id="winDiv" className="outline-0 fixed md:text-[30px] text-[25px] rounded-[60px] md:w-[20%] md:h-[69px] w-[68%] h-[55px] text-center
        text-black md:left-[40%] md:right-[40%] top-[52%] left-[16%] right-[16%] text-[100px] transition-all duration-[400ms] border-black border-[3px] md:border-[4px]
        transition-all duration-[400ms] bg-[#90ee90] scale-0 uppercase"></input>
      <button id="playAgainButton" className="fixed md:text-[30px] text-[25px] text-white rounded-[60px] md:w-[20%] md:h-[69px] w-[68%] h-[55px] text-center
      text-black md:left-[40%] md:right-[40%] left-[16%] right-[16%] top-[61%] text-[100px] transition-all duration-[400ms] border-black border-[4px]
      transition-all duration-[400ms] bg-black hover:opacity-[85%] scale-0" onClick={restartGameRequest}>Play Again!</button>
        <button id="playButton" className="fixed scale-[0.1%] md:text-[30px] md:w-[20%] md:h-[69px] md:left-[40%] md:text-[100px] md:right-[40%] md:top-[58%] md:border-[4px]
        text-[23px] border-[3px] w-1/2 h-[55px] top-[52%] left-1/4 right-1/4 text-black rounded-[60px] text-center
        text-black transition-all duration-[400ms] border-black 
        transition-all duration-[400ms] bg-white hover:opacity-[85%] " onClick={exitMenu}>Play!</button>
      <button id="helpButton" className="fixed md:text-[30px] text-black rounded-[60px] md:border-[4px] md:w-[50px] md:h-[50px] text-center
      text-black md:left-[40%] md:left-[5%] md:top-[50px] text-[25px] w-[40px] h-[40px] left-[9%] top-[37px] border-[3px] transition-all duration-[400ms] border-black 
      transition-all duration-[400ms] bg-transparent hover:opacity-[75%]" onClick={displayHelp}>?</button>
  <div id="helpBox" className="fixed md:left-[25%] md:right-[25%] md:bottom-[20%] md:top-[20%] md:h-[60%] md:w-[50%] 
  bg-white rounded-[100px]    md:max-h-[1000px]  max-h-[570px]  left-[10%] right-[10%] bottom-[10%] top-[10%] h-[80%] w-[80%]
  border-black border-[4px] opacity-[99%] pt-[15px] px-[5%] justify-items-center content-center transition-all duration-[400ms] scale-[0.01%]">
        <div id="mobileBuffer" className="buffer mx-full md:h-[0.01px] h-[5%]" ></div>
        <h10 id="helpBoxTitle" className="md:text-[55px] text-[35px] underline">How to play:</h10>  
          <h9 id="helpBoxBody" className="absolute md:text-[40px] text-[25px] md:top-[4%] md:top-[5%] md:right-[7%] top-[86%] right-[40%] ">&#40;{helpPage}/6&#41;</h9>
        <button id="closeHelpButton" className="fixed md:text-[30px] text-[25px] text-black rounded-[60px] w-[70px] h-[70px] text-center
        text-black md:left-[-5%] md:top-[-50px] left-[-10%] top-[-50px] text-[100px] transition-all duration-[400ms] border-black border-[4px]
        transition-all duration-[400ms] bg-white hover:opacity-[75%]" onClick={closeHelp}>X</button>
          {(helpPage != "1") && (<button id="leftArrowButtonHelp" className="absolute md:text-[40px] text-[35px] bg-black rounded-full md:w-[60px] md:h-[60px]  l:w-[45px] l:h-[45px]
            w-[60px] h-[60px] text-white  mx-auto my-1 transition-all duration-[400ms] hover:opacity-75 bottom-[5%] md:left-[5%] left-[12%] " onClick={decreaseHelp}>&lt;</button>)}
          {(helpPage != "6") && (<button id="rightArrowButtonHelp" className="absolute md:text-[40px] text-[35px] bg-black rounded-full md:w-[60px] md:h-[60px]  l:w-[45px] l:h-[45px]
            w-[60px] h-[60px] text-white  mx-auto my-1 transition-all duration-[400ms] hover:opacity-75 bottom-[5%] md:right-[5%] right-[12%] " onClick={increaseHelp}>&gt;</button>)}
          {(helpPage == "1") && (<div className="buffer mx-full md:h-[20%] h-[18%]" ></div>)}
          {(helpPage == "1") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] leading-tight">Jinx is a word association game played between you and a friend!</h9>)}
          {(helpPage == "1") && (<div className="buffer mx-full h-[6%]" ></div>)}
          {(helpPage == "1") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] leading-tight">To get started...</h9>)}
          {(helpPage == "2") && (<div className="buffer mx-full h-[10%]" ></div>)}
          {(helpPage == "2") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] leading-tight">Generate a friend code and send it to your friend</h9>)}
          {(helpPage == "2") && (<div className="buffer mx-full h-[2%]" ></div>)}
          {(helpPage == "2") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] leading-tight font-bold">OR</h9>)}
          {(helpPage == "2") && (<div className="buffer mx-full h-[2%]" ></div>)}
          {(helpPage == "2") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] leading-tight">Enter a friend code that was sent to you </h9>)}
          
          {(helpPage == "3") && (<div className="buffer mx-full h-[7%]" ></div>)}
          {(helpPage == "3") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] leading-tight">On round one, you and your friend choose any random words you can think of!</h9>)}
          {(helpPage == "3") && (<div className="buffer mx-full h-[3%]" ></div>)}
          {(helpPage == "3") && (<div className="absolute md:left-[10%] md:right-[55%] md:w-[35%] w-[45%] left-[4%] right-[51%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#5BACF6] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Egg</div>)}
          {(helpPage == "3") && (<div className="absolute md:left-[55%] md:right-[10%] md:w-[35%] w-[45%] left-[51%] right-[4%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#FF993A] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Shop</div>)}
          {(helpPage == "4") && (<div className="buffer mx-full h-[7%]" ></div>)}
          {(helpPage == "4") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] 
          leading-tight">Then, find similarities between you and your friends words to come up with your next word. </h9>)}
          {(helpPage == "4") && (<div className="buffer mx-full h-[3%]" ></div>)}
          {(helpPage == "4") && (<div className="absolute md:left-[10%] md:right-[55%] md:w-[35%] w-[45%] left-[4%] right-[51%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#5BACF6] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Grocery</div>)}
          {(helpPage == "4") && (<div className="absolute md:left-[55%] md:right-[10%] md:w-[35%] w-[45%] left-[51%] right-[4%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#FF993A] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Market</div>)}
          {(helpPage == "4") && (<div className="buffer mx-full md:h-[15%] h-[70px]" ></div>)}
          {(helpPage == "4") && (<div className="absolute md:left-[10%] md:right-[55%] md:w-[35%] w-[45%] left-[4%] right-[51%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#DFDFDF] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Egg</div>)}
          {(helpPage == "4") && (<div className="absolute md:left-[55%] md:right-[10%] md:w-[35%] w-[45%] left-[51%] right-[4%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#DFDFDF] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Shop</div>)}
          {(helpPage == "5") && (<div className="buffer mx-full h-[3%]" ></div>)}
          {(helpPage == "5") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] 
          leading-tight">Keep going until you both type the same thing!</h9>)}
          {(helpPage == "5") && (<div className="buffer mx-full h-[3%]" ></div>)}
          {(helpPage == "5") && (<div className="absolute md:left-[10%] md:right-[55%] md:w-[35%] w-[45%] left-[4%] right-[51%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#90ee90] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Food</div>)}
          {(helpPage == "5") && (<div className="absolute md:left-[55%] md:right-[10%] md:w-[35%] w-[45%] left-[51%] right-[4%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#90ee90] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Food</div>)}
          {(helpPage == "5") && (<div className="buffer mx-full md:h-[15%] h-[70px]" ></div>
          )}
          {(helpPage == "5") && (<div className="absolute md:left-[10%] md:right-[55%] md:w-[35%] w-[45%] left-[4%] right-[51%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#DFDFDF] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Grocery</div>)}
          {(helpPage == "5") && (<div className="absolute md:left-[55%] md:right-[10%] md:w-[35%] w-[45%] left-[51%] right-[4%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#DFDFDF] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Market</div>)}
          {(helpPage == "5") && (<div className="buffer mx-full md:h-[15%] h-[70px]" ></div>)}
          {(helpPage == "5") && (<div className="absolute md:left-[10%] md:right-[55%] md:w-[35%] w-[45%] left-[4%] right-[51%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#DFDFDF] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Egg</div>)}
          {(helpPage == "5") && (<div className="absolute md:left-[55%] md:right-[10%] md:w-[35%] w-[45%] left-[51%] right-[4%] md:h-[69px] h-[60px] outline-0 md:text-[30px] text-[23px] bg-[#DFDFDF] 
          rounded-[60px] text-center text-black border-black border-[4px] uppercase leading-loose">Shop</div>)}
          {(helpPage == "6") && (<div className="buffer mx-full h-[16%]" ></div>)}
          {(helpPage == "6") && (<h9 id="helpBoxBody" className="md:text-[40px] text-[23px] leading-tight">All rules are left open to you and your friend, so let your creativity shine! </h9>)}
          <div className="buffer mx-full h-[1px]" ></div>
  </div>
</div>
  );
}

export default App;
